import React from "react";
import { Button, Card, Col, Form, Image, Row, Switch } from "antd";
import imageSrc from "../../../asset/image/image 2.png";
import useHttp from "../../../hooks/use-http";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";

const UserProfile = ({
  name,
  gender,
  profilePic,
  dob,
  mobile,
  email,
  isBlocked,
  setRefresh,
}) => {
  const { id } = useParams();
  const dataId = id;
  const api = useHttp();
  const blockUser = (value) => {
    const payload = {
      isBlocked:value
    }
    // console.log(payload);
    const USER_BLOCK_API = apiGenerator(CONSTANTS.API.editUsers, { dataId })
    api.sendRequest(USER_BLOCK_API, () => {
      setRefresh(prev => !prev)
    },payload,`${value?'User is Blocked':'User is Unblocked'}`)
  }
  return (
    <Card className="h-72">
      <div>
        <div className="ml-5">
          <Row>
            <Col span={18}>
              <Image
                width={80}
                height={80}
                className="rounded-full"
                preview={false}
                src={profilePic || imageSrc}
                alt="Images is Not Availabel"
              />
            </Col>
            <Col span={3}>
              <Form.Item id='toggle' name='toggle' >
                <Switch
                  className="mt-8"
                  checked={isBlocked}
                  onChange={blockUser}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-3">
            <Col span={14}>
              <p className="font-medium  px-3 text-xl">{name}</p>
              <p className="font-medium -mt-6 px-3 text-sm">{gender}</p>
            </Col>
            <Col span={10}>
              <p className="font-medium  px-3 text-base">{dob}</p></Col>
          </Row>
          <div className="my-6">
            <p className="font-medium  px-3 text-lg">{mobile}</p>
            <p className="font-medium -mt-4 px-3 text-lg">{email}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UserProfile;

UserProfile.defaultProps = {
  name:'no data found',
  gender:'no data found',
  profilePic:imageSrc,
  dob:'no data found',
  mobile:'no data found',
  email:'no data found',
}
