import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";

const Story = () => {
  const API = useHttp();
  const navigate = useNavigate();

  const userDetail = (id) => {
    navigate(`/app/story/${id}`);
  };
  React.useEffect(() => {
    API.sendRequest(CONSTANTS.API.getTags, (res) => {
      const tags = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.EDIT_CARD_MODAL.find(
        (el) => el?.id === "tag"
      ).option = tags;
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL.find(
        (el) => el?.id === "tag"
      ).option = tags;
      CONSTANTS.FORM_FIELD.EDIT_STORY_MODAL.find(
        (el) => el?.id === "tag"
      ).option = tags;
      CONSTANTS.FORM_FIELD.STORY_MODAL.find((el) => el?.id === "tag").option =
        tags;
    });
    API.sendRequest(CONSTANTS.API.getGods, (res) => {
      const gods = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.EDIT_CARD_MODAL.find(
        (el) => el?.id === "god"
      ).option = gods;
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL.find(
        (el) => el?.id === "god"
      ).option = gods;
      CONSTANTS.FORM_FIELD.EDIT_STORY_MODAL.find(
        (el) => el?.id === "god"
      ).option = gods;
      CONSTANTS.FORM_FIELD.STORY_MODAL.find((el) => el?.id === "god").option =
        gods;
      console.log(gods, CONSTANTS.FORM_FIELD.STORY_MODAL);
    });
    API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
      const languages = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL_INNER.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.QUESTION.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.STORY_TRANSLATIONS.find(
        (el) => el?.id === "languageId"
      ).option = languages;
    });
  }, []);
  return (
    <div>
      <PageComponent
        tableHeaders="STORY"
        tableTitle="Story List"
        getAPI={CONSTANTS.API.getStory}
        extraResData={"rows"}
        getData={(res) => {
          console.log(res);
          return res?.map((data) => {
            return {
              ...data,
              no: `S${data?.id.toString().padStart(7, "0")}`,
              god: data?.storyGods?.map((ele) => ele?.god?.id),
              tag: data?.storyTags?.map((ele) => ele?.tag?.id),
              tagD: data?.storyTags?.map((elp) => <p>{elp?.tag?.name}</p>),
              godD: data?.storyGods?.map((elp) => <p>{elp?.god?.name}</p>),
              createdAt: moment
                .utc(data?.createdAt)
                .local()
                .format("DD MMM, YYYY"),
            };
          });
        }}
        formData
        modalButton="Add New Story"
        modalFields="STORY_MODAL"
        addModalTitle="Add Story Data"
        addAPI={CONSTANTS.API.addStory}
        blockData
        viewData
        viewFunction={userDetail}
        editformData
        editModalFields="EDIT_STORY_MODAL"
        editModalTitle="Edit Story Data"
        editAPI={CONSTANTS.API.editStory}
        deleteData
        deleteAPI={CONSTANTS.API.deleteStory}
        searchfilter
        isSearch
        searchAPI="/admin/story?searchQuery="
      />
    </div>
  );
};

export default Story;
