import React from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';

const God = () => {
  return (
    <>
      <PageComponent
        tableHeaders="GOD"
        tableTitle="God List"
        getAPI={CONSTANTS.API.getGods}
        extraResData={"rows"}
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `G${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("DD MMM, YYYY"),
            };
          });
        }}
        formData
        modalButton="Add New God"
        modalFields="GOD_MODAL"
        addModalTitle="Add God Data"
        addAPI={CONSTANTS.API.addGod}
        viewData
        viewFunction={() => {}}
        editformData
        blockData
        editModalFields="EDIT_GOD_MODAL"
        editModalTitle="Edit God Data"
        editAPI={CONSTANTS.API.editGod}
        deleteData
        deleteAPI={CONSTANTS.API.deleteGod}
      />
    </>
  );
}

export default God