import Eye from "../../asset/image/eye.png";
import { Button, Image, Input, Popconfirm, Select, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  BellOutlined
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import moment from "moment/moment";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
const RenderEditButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// Notification Button
const RenderNotificationButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <BellOutlined />
    </Button>
  );
};

// View Image Button
const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={Eye}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined
        onClick={() => {
          onClick(id);
        }}
      />
    </Button>
  );
};

// Render Images
const ImageRanders = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};

// Checkbox render
const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

export const loginRoot = "/";
export const appRoot = "/app";


const CONSTANTS = {
  GETMe: null,
  TABLE: {
    DARSHAN: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 140,
      },

      {
        title: "BoostAt",
        dataIndex: "boostAt",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "God",
        dataIndex: "god",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 400,
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    DARSHAN_USER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Join Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

    ],
    USERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,

        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 250,
      },
      {
        title: "Join Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 130,
      },

      {
        title: "Block User",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 100,
      },
      {
        title: "Action",
        dataIndex: "notification",
        render: RenderNotificationButton,
        width: 75,

      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
        width: 65,

      },
    ],
    USER_STORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,

        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },

      {
        title: "Tag",
        dataIndex: "tag",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "God",
        dataIndex: "god",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "createdAt",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

    ],
    USER_READ: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,

        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "God",
        dataIndex: "god",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Tag",
        dataIndex: "tag",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },


    ],
    USER_DARSHAN: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,

        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },

      {
        title: "BoostAt",
        dataIndex: "boostAt",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "God",
        dataIndex: "god",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },


    ],
    USER_ARTI: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 150,
      },
      {
        title: "God",
        dataIndex: "god",
        sorter: (a, b) => alphanumericSort(a, b, "godD"),
        width: 130,
      },
      {
        title: "Language",
        dataIndex: "language",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 130,
      },
      {
        title: "Tag",
        dataIndex: "tag",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 130,
      },
      {
        title: "Preview Track",
        dataIndex: "track",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 280,
      },

    ],
    USER_FAMILY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,

        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },

      {
        title: "Gender",
        dataIndex: "gender",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Relation",
        dataIndex: "relation",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Date Of Birth",
        dataIndex: "dob",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },


    ],
    USER_FEEDBACK: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },


      {
        title: "Star",
        dataIndex: "rating",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },

      {
        title: "Like",
        dataIndex: "likeSectionSuggestion",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Improve",
        dataIndex: "improveSectionSuggestion",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },

      {
        title: "Suggestions",
        dataIndex: "feedback",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },


    ],
    CARD_TRANSLATION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 200,
      },

      {
        title: "Short Description",
        dataIndex: "shortDescription",
        sorter: (a, b) => alphanumericSort(a, b, "shortDescription"),
        width: 220,
      },
      {
        title: "Language",
        dataIndex: "language",
        sorter: (a, b) => alphanumericSort(a, b, "language"),
        width: 200,
        // render: (v) => {
        //   console.log(v);
        //   return v;
        // },
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
        width: 65,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 65,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 65,
      },
    ],
    CARD_QUESTION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "Question",
        dataIndex: "question",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 300,
      },

      {
        title: "Ans",
        dataIndex: "answer",
        sorter: (a, b) => alphanumericSort(a, b, "answer"),
        width: 200,
      },
      {
        title: "Language",
        dataIndex: "language",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 200,
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    GOD: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "icon",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "God Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "createdAt",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 35,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 40,
      },
    ],
    TAG: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Tag",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "createdAt",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 250,
      },

      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 40,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 50,
      },
    ],
    LANGUAGE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Language",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "createdAt",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 250,
      },

      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 40,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 50,
      },
    ],
    STORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Tag",
        dataIndex: "tagD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 160,
      },
      {
        title: "God",
        dataIndex: "godD",
        sorter: (a, b) => alphanumericSort(a, b, "godD"),
        width: 160,
      },
      {
        title: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 130,
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
        width: 65,

        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 65,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 65,
      },

    ],
    STORY_EPISODE_SUGGESTION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 160,
      },

      {
        title: "Tag",
        dataIndex: "tagD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 100,
      },



      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 65,
      },

    ],
    STORY_CARD_SUGGESTION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "God",
        dataIndex: "godD",
        sorter: (a, b) => alphanumericSort(a, b, "godD"),
        width: 160,
      },
      {
        title: "Tag",
        dataIndex: "tagD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 160,
      },



      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 65,
      },

    ],
    STORY_EPISODE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "BG File",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 400,
      },

      {
        title: "Tag",
        dataIndex: "tagD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 210,
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },

    ],
    STORY_USER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Image",
        dataIndex: "icon",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Join Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    CARD_LIST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 90,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 150,
      },
      {
        title: "God",
        dataIndex: "godD",
        sorter: (a, b) => alphanumericSort(a, b, "godD"),
        width: 300,
      },
      {
        title: "Tag",
        dataIndex: "tagD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 220,
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    ARTI_LIST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 150,
      },
      {
        title: "SubTitle",
        dataIndex: "subTitle",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 150,
      },
      {
        title: "God",
        dataIndex: "godD",
        sorter: (a, b) => alphanumericSort(a, b, "godD"),
        width: 130,
      },
      {
        title: "Language",
        dataIndex: "languageD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 130,
      },
      {
        title: "Tag",
        dataIndex: "tagD",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 130,
      },
      {
        title: "Likes",
        dataIndex: "totalBookmarksCount",
        sorter: (a, b) => alphanumericSort(a, b, "totalBookmarksCount"),
        width: 80,
      },
      {
        title: "Preview Track",
        dataIndex: "track",
        sorter: (a, b) => alphanumericSort(a, b, "tagD"),
        width: 280,
      },
      {
        title: "",
        dataIndex: "view",
        render: RenderViewButton,
        width: 75
      },
      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 75
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 70
      },
    ],
    ARTI_USER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 170,
      },

      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
        width: 170,
      },
      {
        title: "Join Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 170,
      },

    ],
    ARTI_SCHEDULER_LIST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 330,
      },
      {
        title: "Date",
        dataIndex: "dateD",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 160,
      },
      {
        title: "Time",
        dataIndex: "timeD",
        sorter: (a, b) => alphanumericSort(a, b, "time"),
        width: 160,
      },


      {
        title: "Action",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 75
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 70
      },
    ],

  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    GOD_MODAL: [
      {
        no: 1,
        Label: "God Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter God Name",
        required: true,
      },

      {
        no: 2,
        Label: "Image",
        name: "icon",
        id: "icon",
        type: "file",
        acceptFormat: '.jpg,.png,.jpeg',
        placeholder: " ",
        required: true,
      },
    ],
    EDIT_GOD_MODAL: [
      {
        no: 1,
        Label: "God Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter God Name",
        required: true,
      },
      {
        no: 2,
        Label: "Image",
        name: "icon",
        id: "icon",
        type: "file",
        acceptFormat: '.jpg,.png,.jpeg',
        placeholder: " ",
        required: false,
      },
    ],
    DARSHAN_MODAL: [
      {
        no: 1,
        Label: "God Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter God Name",
        required: true,
      },
      {
        no: 2,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Enter God Name",
        option: [],
        required: true,
      },
      {
        no: 3,
        Label: "BoostAT",
        name: "boostAt",
        id: "boostAt",
        type: "date",
        format: "DD/MM/YYYY",
        placeholder: "Enter BoostAT",
        required: true,
      },
      {
        no: 4,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: '.jpg,.png,.jpeg',
        placeholder: " ",
        required: true,
      },
    ],
    EDIT_DARSHAN_MODAL: [
      {
        no: 1,
        Label: "God Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter God Name",
        required: true,
      },
      {
        no: 2,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Enter God Name",
        option: [],
        required: false,
      },
      {
        no: 3,
        Label: "BoostAT",
        name: "boostAt",
        id: "boostAt",
        type: "date",
        format: "DD/MM/YYYY",
        placeholder: "Enter BoostAT",
        required: true,
      },
      {
        no: 4,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        acceptFormat: '.jpg,.png,.jpeg',
        required: false,
      },
    ],

    TAG_MODAL: [
      {
        no: 1,
        Label: "Tag Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Tag Name",
        required: true,
      },
    ],
    LANGUAGE_MODAL: [
      {
        no: 1,
        Label: "Language Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Language Name",
        required: true,
      },
    ],
    EDIT_STORY_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title ",
        required: true,
      },
      {
        no: 2,
        Label: "Tag",
        name: "tag",
        id: "tag",
        mode: "multiple",
        type: "select",
        placeholder: "Enter Tags ",
        option: [],
        required: true,
      },
      {
        no: 3,
        Label: "God",
        name: "god",
        id: "god",
        mode: "multiple",
        type: "select",
        placeholder: "Enter God Name",
        option: [],
        required: true,
      },
      {
        no: 4,
        Label: "BoostAT",
        name: "boostAt",
        id: "boostAt",
        type: "date",
        format: "DD/MM/YYYY",
        placeholder: "Enter BoostAT",
        required: true,
      },
      {
        no: 5,
        Label: "Background File (360*768 PX)",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: '.jpg,.png,.jpeg',
        placeholder: " ",
        required: false,
      },
    ],
    STORY_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Enter Tags",
        option: [],
        required: true,
      },
      {
        no: 3,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Enter God Name",
        option: [],
        required: true,
      },
      {
        no: 4,
        Label: "BoostAT",
        name: "boostAt",
        id: "boostAt",
        type: "date",
        format: "DD/MM/YYYY",
        placeholder: "Enter BoostAT",
        required: true,
      },
      {
        no: 5,
        Label: "Background File (360*768 PX)",
        name: "image",
        id: "image",
        acceptFormat: '.jpg,.png,.jpeg',
        type: "file",
        placeholder: " ",
        required: true,
      },
      {
        no: 6,
        Label: "",
        name: "storyTranslation",
        id: "storyTranslation",
        type: "multifield",
        menu: "STORY_TRANSLATIONS",
        menuLabel: 'Story Translation',
        option: [],
        initialValue: [{}],
        placeholder: " ",
        addName: "Add New Translation",
        removeName: "Remove Translation",
        required: true,
      },
    ],
    STORY_TRANSLATIONS: [
      {
        no: 1,
        Label: "Language",
        name: "languageId",
        id: "languageId",
        type: "select",
        placeholder: "Please Select Language",
        option: [],
        required: true,
        width: 12,
      },
      {
        no: 2,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Please Enter Title",
        required: true,
      },
      {
        no: 3,
        Label: "Short Description",
        name: "sortDescription",
        id: "sortDescription",
        type: "text",
        placeholder: "Please Enter Short Description",
        required: true,
      },
    ],
    EDIT_STORY_EPISODE: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Enter Tags",
        option: [],
        required: true,
      },
      {
        no: 5,
        Label: "Background File (360*768 PX)",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: '.jpg,.jpeg,.png',
        placeholder: " ",
        required: false,
      },
    ],
    NEW_STORY_EPISODE: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Enter Tags",
        option: [],
        required: true,
      },
      {
        no: 5,
        Label: "Background File (360*768 PX)",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: '.jpg,.jpeg,.png',
        placeholder: " ",
        required: true,
      },
      {
        no: 6,
        Label: "",
        name: "storyEpisodeTranslation",
        id: "storyEpisodeTranslation",
        type: "multifield",
        menu: "EPISODE_STORY_TRANSLATIONS",
        menuLabel: 'Story Episode Translation',
        option: [],
        initialValue: [{}],
        placeholder: " ",
        addName: "Add Translation",
        removeName: "Remove Translation",
        required: true,
      },
    ],
    EPISODE_STORY_TRANSLATIONS: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Please Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Short Description",
        name: "shortDescription",
        id: "shortDescription",
        type: "text",
        placeholder: "Please Enter Short Description",
        required: true,
      },
      {
        no: 3,
        Label: "Video Link",
        name: "videoLink",
        id: "videoLink",
        type: "text",
        placeholder: "Please Enter Link",
        required: true,
      },
      {
        no: 4,
        Label: "Language",
        name: "languageId",
        id: "languageId",
        type: "select",
        placeholder: "Please Select Language",
        option: [],
        required: true,
      },
    ],
    NOTIFICATION_MODAL: [
      {
        no: 1,
        Label: "Notification Title",
        name: "notification",
        id: "notification",
        type: "text",
        placeholder: "Enter Notification Title",
        required: true,
      },

      {
        no: 2,
        Label: "Message",
        name: "message",
        id: "message",
        type: "text",
        placeholder: "Enter Message",
        required: true,
        // width: 24,
      },
    ],
    STORY_EPISODE_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter  Title",
        required: true,
      },
      {
        no: 1,
        Label: "Episode",
        name: "episode",
        id: "episode",
        type: "file",
        acceptFormat: '.mp4,.mkv',

        placeholder: "",
        required: true,
      },
    ],
    EDIT_STORY_EPISODE_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter  Title",
        required: true,
      },
      {
        no: 1,
        Label: "Episode",
        name: "episode",
        id: "episode",
        type: "file",
        placeholder: "",
        acceptFormat: '.mp4,.mkv',
        required: false,
      },
    ],
    EDIT_CARD_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Enter Tag Name",
        option: [],
        required: true,
      },
      {
        no: 3,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Enter God Name",
        option: [],
        required: true,
      },

      {
        no: 4,
        Label: "Background File (360*768 PX)",
        name: "image",
        id: "image",
        acceptFormat: '.jpg,.png,.jpeg',
        type: "file",
        placeholder: " ",
        required: false,
      },
    ],
    ADD_CARD_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Enter Tag Name",
        option: [],
        required: true,
      },
      {
        no: 3,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Enter God Name",
        option: [],
        required: true,
      },

      {
        no: 4,
        Label: "Background File (360*768 PX)",
        name: "image",
        id: "image",
        acceptFormat: '.jpg,.png,.jpeg',
        type: "file",
        placeholder: " ",
        required: true,
      },
      {
        no: 5,
        type: "multifield",
        menu: "ADD_CARD_MODAL_INNER",
        id: "cardTranslation",
        name: "cardTranslation",
        addName: "Add Translation",
        removeName: "Remove Translation",
        initialValue: [{}],
      },
    ],
    ADD_CARD_MODAL_INNER: [
      {
        no: 1,
        Label: "Language",
        name: "languageId",
        id: "languageId",
        type: "select",
        placeholder: "Enter Language",
        option: [],
        required: true,
      },
      {
        no: 2,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },

      {
        no: 3,
        Label: "Desciption Small",
        name: "shortDescription",
        id: "shortDescription",
        type: "text",
        placeholder: "Enter short Description",
        required: true,
      },
      {
        no: 4,
        Label: "Description",
        name: "description",
        id: "description",
        type: "richTextarea",
        placeholder: "Enter Description",
        required: true,
      },
    ],
    OPTIONS: [
      {
        no: 1,
        Label: "Option 1",
        name: "option1",
        id: "option1",
        type: "text",
        placeholder: "Enter Option 1",
        required: true,
      },
      {
        no: 2,
        Label: "Option 2",
        name: "option2",
        id: "option2",
        type: "text",
        placeholder: "Enter Option 2",
        required: true,
      },
      {
        no: 3,
        Label: "Option 3",
        name: "option3",
        id: "option3",
        type: "text",
        placeholder: "Enter Option 3",
        required: true,
      },
      {
        no: 4,
        Label: "Option 4",
        name: "option4",
        id: "option4",
        type: "text",
        placeholder: "Enter Option 4",
        required: true,
      },
    ],
    QUESTION: [
      {
        no: 1,
        Label: "Language",
        name: "languageId",
        id: "languageId",
        type: "select",
        placeholder: "Enter Language",
        option: [],
        required: true,

      },
      {
        no: 2,
        Label: "Question",
        name: "question",
        id: "question",
        type: "text",
        placeholder: "Enter Question",
        required: true,
      },
      {
        no: 3,
        Label: "options",
        name: "options",
        id: "options",
        type: "option",
        placeholder: "Enter options",
        required: true,
        // handler: (res) => {
        //   console.log(res, "extraMultiSingle");
        //   return res;
        // },
        // menu: "OPTIONS",
      },
      {
        no: 2,
        Label: "Answer",
        name: "answers",
        id: "answers",
        type: "select",
        placeholder: "Select Answer",
        option: [
          {
            id: 1,
            Label: 'Option 1',
            value: 0,
          },
          {
            id: 2,
            Label: 'Option 2',
            value: 1,
          },
          {
            id: 3,
            Label: 'Option 3',
            value: 2,
          },
          {
            id: 4,
            Label: 'Option 4',
            value: 3,
          },
        ],
        required: true,
      },
    ],
    VIEW_QUESTION: [
      {
        no: 1,
        Label: "Language",
        name: "languageId",
        id: "languageId",
        type: "select",
        placeholder: "Enter Language",
        option: [],
        required: true,
        disabled: true,

      },
      {
        no: 2,
        Label: "Question",
        name: "question",
        id: "question",
        type: "text",
        placeholder: "Enter Question",
        required: true,
        disabled: true,
      },
      {
        no: 3,
        Label: "options",
        name: "options",
        id: "options",
        type: "option",
        placeholder: "Enter options",
        required: true,
        disabled: true,
        // handler: (res) => {
        //   console.log(res, "extraMultiSingle");
        //   return res;
        // },
        // menu: "OPTIONS",
      },
      {
        no: 2,
        Label: "Answer",
        name: "answer",
        id: "answer",
        type: "text",
        placeholder: "Enter Answer",
        required: true,
        disabled: true,
      },
    ],
    ARTI_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "SubTitle",
        name: "subTitle",
        id: "subTitle",
        type: "text",
        placeholder: "Enter SubTitle",
        required: true,
      },
      {
        no: 3,
        Label: "Artist",
        name: "artist",
        id: "artist",
        type: "text",
        placeholder: "Enter Artist",
        required: true,
      },
      {
        no: 4,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Select Tags",
        option: [],
        required: true,
      },
      {
        no: 5,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Select God",
        option: [],
        required: true,
      },
      {
        no: 6,
        Label: "Language",
        name: "artiLanguage",
        id: "artiLanguage",
        type: "select",
        mode: "multiple",
        placeholder: "Select Language",
        option: [],
        required: true,
      },
      {
        no: 7,
        Label: "Background File (156*219 PX)",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: '.jpg,.png,.jpeg',
        placeholder: " ",
        required: true,
      },
      {
        no: 8,
        Label: "Preview Track",
        name: "previewTrack",
        id: "previewTrack",
        type: "file",
        acceptFormat: '.mp3',
        placeholder: " ",
        required: true,
      },

    ],
    EDIT_ARTI_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "SubTitle",
        name: "subTitle",
        id: "subTitle",
        type: "text",
        placeholder: "Enter SubTitle",
        required: true,
      },
      {
        no: 3,
        Label: "Artist",
        name: "artist",
        id: "artist",
        type: "text",
        placeholder: "Enter Artist",
        required: true,
      },
      {
        no: 4,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "select",
        mode: "multiple",
        placeholder: "Select Tags",
        option: [],
        required: true,
      },
      {
        no: 5,
        Label: "God",
        name: "god",
        id: "god",
        type: "select",
        mode: "multiple",
        placeholder: "Select God",
        option: [],
        required: true,
      },
      {
        no: 6,
        Label: "Language",
        name: "artiLanguage",
        id: "artiLanguage",
        type: "select",
        mode: "multiple",
        placeholder: "Select Language",
        option: [],
        required: true,
      },
      {
        no: 7,
        Label: "Background File (156*219 PX)",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: '.jpg,.png,.jpeg',
        placeholder: " ",
        required: false,
      },
      {
        no: 8,
        Label: "Preview Track",
        name: "previewTrack",
        id: "previewTrack",
        type: "file",
        acceptFormat: '.mp3',
        placeholder: "Add Track",
        required: false,
      },
    ],
    ARTI_SCHEDULER_MODAL: [
      {
        no: 1,
        Label: "Arti",
        name: "artiId",
        id: "artiId",
        type: "select",
        placeholder: "Select Arti",
        option: [],
        required: true,
      },

      {
        no: 2,
        Label: "Date",
        name: "date",
        id: "date",
        type: "date",
        placeholder: "Enter Date",
        required: true,
        showTime: false,
      },
      {
        no: 3,
        Label: "Time",
        name: "time",
        id: "time",
        type: "time",
        placeholder: "Enter Time",
        required: true,
      },


    ],
    STORY_EPISODE_SUGGESTION_MODAL: [


      {
        no: 1,
        Label: "Title",
        name: "suggestionStoryEpisodeId",
        id: "suggestionStoryEpisodeId",
        type: "select",
        placeholder: "Select Title",
        option: [],
        required: true,
      },

    ],
    STORY_CARD_SUGGESTION_MODAL: [


      {
        no: 1,
        Label: "Title",
        name: "cardId",
        id: "cardId",
        type: "select",
        placeholder: "Select Title",
        option: [],
        required: true,
      },

    ],
    USER_NOTIFICATION_MODAL: [
      {
        no: 1,
        Label: "Type",
        name: "type",
        id: "type",
        type: "select",
        placeholder: "Select a Type",
        option: [
          {
            id: 1,
            Label: 'General',
            value: 'General',
          },
          {
            id: 2,
            Label: 'Reminder',
            value: 'reminder',
          },
        ],
        required: true,
      },
      {
        no: 2,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter a Title",
        required: true,
      },
      {
        no: 3,
        Label: "Description",
        name: "body",
        id: "body",
        type: "text",
        placeholder: "Enter a Description",
        required: true,
      },
      {
        no: 4,
        Label: "Click Action",
        name: "clickAction",
        id: "clickAction",
        type: "select",
        placeholder: "Select an Click Action",
        option: [
          {
            id: 1,
            Label: 'Home',
            value: 'Home',
          },

          {
            id: 2,
            Label: 'Profile',
            value: 'Profile',
          },
          {
            id: 3,
            Label: 'Story',
            value: 'Story',
          },
          {
            id: 4,
            Label: 'Read',
            value: 'Read',
          },
          {
            id: 5,
            Label: 'Arti',
            value: 'Arti',
          },
          {
            id: 6,
            Label: 'FamilyMember',
            value: 'FamilyMember',
          },
          {
            id: 7,
            Label: 'Rating',
            value: 'Rating',
          },
          {
            id: 8,
            Label: 'Tour',
            value: 'Tour',
          },
        ],
        required: true,
      },

    ],
    GENERAL_NOTIFICATION_MODAL: [

      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter a Title",
        required: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "body",
        id: "body",
        type: "text",
        placeholder: "Enter a Description",
        required: true,
      },
      {
        no: 3,
        Label: "Click Action",
        name: "clickAction",
        id: "clickAction",
        type: "select",
        placeholder: "Select an Click Action",
        option: [
          {
            id: 1,
            Label: 'Home',
            value: 'Home',
          },

          {
            id: 2,
            Label: 'Profile',
            value: 'Profile',
          },
          {
            id: 3,
            Label: 'Story',
            value: 'Story',
          },
          {
            id: 4,
            Label: 'Read',
            value: 'Read',
          },
          {
            id: 5,
            Label: 'Arti',
            value: 'Arti',
          },
          {
            id: 6,
            Label: 'FamilyMember',
            value: 'FamilyMember',
          },
          {
            id: 7,
            Label: 'Rating',
            value: 'Rating',
          },
          {
            id: 8,
            Label: 'Tour',
            value: 'Tour',
          },
        ],
        required: true,
      },

    ],
  },

  API: {
    // Login API
    login: {
      type: "POST",
      endpoint: "/admin/admin/login",
    },
    // signUp: { type: "POST", endpoint: "/user/signup" },
    getMe: {
      type: "GET",
      endpoint: "/admin/admin/profile",
    },


    // Analysis API
    getAnalysis: {
      type: "GET",
      endpoint: "/admin/analysis",
    },

    // Darshan API
    getDarshans: {
      type: "GET",
      endpoint: "/admin/darashan",
    },
    addDarshan: {
      type: "POST",
      endpoint: "/admin/darashan",
    },
    editDarshan: {
      type: "PATCH",
      endpoint: "/admin/darashan/:dataId",
    },
    deleteDarshan: {
      type: "DELETE",
      endpoint: "/admin/darashan/:dataId",
    },

    // Get Single User API
    getOneUsers: {
      type: "GET",
      endpoint: "/admin/users/:dataId",
    },
    // User API
    getUsers: {
      type: "GET",
      endpoint: "/admin/users",
    },
    editUsers: {
      type: "PATCH",
      endpoint: "/admin/users/:dataId",
    },
    // User Story API
    getUserStory: {
      type: "GET",
      endpoint: "/admin/storyEpisodeUser",
    },
    // User Card API
    getUserCard: {
      type: "GET",
      endpoint: "/admin/userReadCard",
    },
    // User Darshan API
    getUserDarshan: {
      type: "GET",
      endpoint: "/admin/darashanUser",
    },
    // User Family API
    getUserFamily: {
      type: "GET",
      endpoint: "/admin/userFamily",
    },
    // User Feedback API
    getUserFeedback: {
      type: "GET",
      endpoint: "/admin/userFeedback",
    },
    // User Personal Notification API
    sendUserPersonalNotification: {
      type: "POST",
      endpoint: "/admin/notification/addPersonalNotification",
    },
    // User General Notification API
    sendUserGeneralNotification: {
      type: "POST",
      endpoint: "/admin/notification/addNotificationToTopic",
    },

    // God API
    getGods: {
      type: "GET",
      endpoint: "/admin/god",
    },
    addGod: {
      type: "POST",
      endpoint: "/admin/god",
    },
    editGod: {
      type: "PATCH",
      endpoint: "/admin/god/:dataId",
    },
    deleteGod: {
      type: "DELETE",
      endpoint: "/admin/god/:dataId",
    },

    // Story API
    getStory: {
      type: "GET",
      endpoint: "/admin/story",
    },
    addStory: {
      type: "POST",
      endpoint: "/admin/story",
    },
    editStory: {
      type: "PATCH",
      endpoint: "/admin/story/:dataId",
    },
    deleteStory: {
      type: "DELETE",
      endpoint: "/admin/story/:dataId",
    },

    //  Story Episode Suggestion API
    getStoryEpisodeSuggestion: {
      type: "GET",
      endpoint: "/admin/storySuggestion",
    },
    addStoryEpisodeSuggestion: {
      type: "POST",
      endpoint: "/admin/storySuggestion",
    },
    deleteStoryEpisodeSuggestion: {
      type: "DELETE",
      endpoint: "/admin/storySuggestion/:dataId",
    },

    //  Story Card Suggestion API
    getStoryCardSuggestion: {
      type: "GET",
      endpoint: "/admin/storyCardSuggestion",
    },
    addStoryCardSuggestion: {
      type: "POST",
      endpoint: "/admin/storyCardSuggestion",
    },
    deleteStoryCardSuggestion: {
      type: "DELETE",
      endpoint: "/admin/storyCardSuggestion/:dataId",
    },

    // Translation Story API
    getCardTranslations: {
      type: "GET",
      endpoint: "/admin/cardTranslation",
    },
    addCardTranslation: {
      type: "POST",
      endpoint: "/admin/cardTranslation",
    },
    editCardTranslation: {
      type: "PATCH",
      endpoint: "/admin/cardTranslation/:dataId",
    },
    deleteCardTranslation: {
      type: "DELETE",
      endpoint: "/admin/cardTranslation/:dataId",
    },
    // Translation Story API
    getStoryEpisodes: {
      type: "GET",
      endpoint: "/admin/storyEpisode",
    },
    addStoryEpisode: {
      type: "POST",
      endpoint: "/admin/storyEpisode",
    },
    editStoryEpisode: {
      type: "PATCH",
      endpoint: "/admin/storyEpisode/:dataId",
    },
    deleteStoryEpisode: {
      type: "DELETE",
      endpoint: "/admin/storyEpisode/:dataId",
    },
    // Tag API
    getTags: {
      type: "GET",
      endpoint: "/admin/tag",
    },
    addTag: {
      type: "POST",
      endpoint: "/admin/tag",
    },
    editTag: {
      type: "PATCH",
      endpoint: "/admin/tag/:dataId",
    },
    deleteTag: {
      type: "DELETE",
      endpoint: "/admin/tag/:dataId",
    },
    // Language API
    getLanguage: {
      type: "GET",
      endpoint: "/admin/language",
    },
    addLanguage: {
      type: "POST",
      endpoint: "/admin/language",
    },
    editLanguage: {
      type: "PATCH",
      endpoint: "/admin/language/:dataId",
    },
    deleteLanguage: {
      type: "DELETE",
      endpoint: "/admin/language/:dataId",
    },

    // Card API
    getCards: {
      type: "GET",
      endpoint: "/admin/card",
    },
    addCard: {
      type: "POST",
      endpoint: "/admin/card",
    },
    editCard: {
      type: "PATCH",
      endpoint: "/admin/card/:dataId",
    },
    deleteCard: {
      type: "DELETE",
      endpoint: "/admin/card/:dataId",
    },
    getCardById: {
      type: "GET",
      endpoint: "/admin/card/:dataId",
    },

    // Translation Card API
    getStoryTranslations: {
      type: "GET",
      endpoint: "/admin/storyTranslation",
    },
    addStoryTranslation: {
      type: "POST",
      endpoint: "/admin/storyTranslation",
    },
    editStoryTranslation: {
      type: "PATCH",
      endpoint: "/admin/storyTranslation/:dataId",
    },
    deleteStoryTranslation: {
      type: "DELETE",
      endpoint: "/admin/storyTranslation/:dataId",
    },
    // Story Episode Translation  API
    getStoryEpisodeTranslations: {
      type: "GET",
      endpoint: "/admin/storyEpisodeTranslation",
    },
    addStoryEpisodeTranslation: {
      type: "POST",
      endpoint: "/admin/storyEpisodeTranslation",
    },
    editStoryEpisodeTranslation: {
      type: "PATCH",
      endpoint: "/admin/storyEpisodeTranslation/:dataId",
    },
    deleteStoryEpisodeTranslation: {
      type: "DELETE",
      endpoint: "/admin/storyEpisodeTranslation/:dataId",
    },
    // Question Card API
    getOneCardQuestionTranslations: {
      type: "GET",
      endpoint: "/admin/cardQuestionTranslation/:dataId",
    },
    // Question Card API
    getCardQuestionTranslations: {
      type: "GET",
      endpoint: "/admin/cardQuestionTranslation",
    },
    addCardQuestionTranslation: {
      type: "POST",
      endpoint: "/admin/cardQuestionTranslation",
    },
    editCardQuestionTranslation: {
      type: "PATCH",
      endpoint: "/admin/cardQuestionTranslation/:dataId",
    },
    deleteCardQuestionTranslation: {
      type: "DELETE",
      endpoint: "/admin/cardQuestionTranslation/:dataId",
    },

    // Arti API
    getArti: {
      type: "GET",
      endpoint: "/admin/arti",
    },
    addArti: {
      type: "POST",
      endpoint: "/admin/arti",
    },
    editArti: {
      type: "PATCH",
      endpoint: "/admin/arti/:dataId",
    },
    deleteArti: {
      type: "DELETE",
      endpoint: "/admin/arti/:dataId",
    },

    // Arti User API
    getArtiUser: {
      type: "GET",
      endpoint: "/admin/userPlayArti",
    },
    // Arti Scheduler API
    getArtiScheduler: {
      type: "GET",
      endpoint: "/admin/artiSchedule",
    },
    addArtiScheduler: {
      type: "POST",
      endpoint: "/admin/artiSchedule",
    },
    editArtiScheduler: {
      type: "PATCH",
      endpoint: "/admin/artiSchedule/:dataId",
    },
    deleteArtiScheduler: {
      type: "DELETE",
      endpoint: "/admin/artiSchedule/:dataId",
    },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "Story",
        name: "story",
        id: "story",
      },
      {
        no: 2,
        Label: "Read",
        name: "read",
        id: "read",
      },
      {
        no: 3,
        Label: "Darashan",
        name: "darshan",
        id: "darshan",
      },
      {
        no: 4,
        Label: "Listen Arti",
        name: "arti",
        id: "arti",
      },
      {
        no: 5,
        Label: "Family",
        name: "family",
        id: "family",
      },
      {
        no: 5,
        Label: "Feedback",
        name: "feedback",
        id: "feedback",
      },
    ],
    CARD: [
      {
        no: 1,
        Label: "Card Translation",
        name: "cardTranslation",
        id: "cardTranslation",
      },
      {
        no: 2,
        Label: "Card Question",
        name: "cardQuestion",
        id: "cardQuestion",
      },
    ],
    STORY: [
      {
        no: 1,
        Label: "Story Translation",
        name: "storyTranslation",
        id: "storyTranslation",
      },
      {
        no: 2,
        Label: "Story Episode",
        name: "storyEpisode",
        id: "storyEpisode",
      },
    ],
    STORY_EPISODE: [
      {
        no: 1,
        Label: "Story Episode Translation",
        name: "translation",
        id: "translation",
      },
      {
        no: 2,
        Label: "Story Episode Suggestion",
        name: "episodeSuggestion",
        id: "episodeSuggestion",
      },
      {
        no: 3,
        Label: "Story Card Suggestion",
        name: "cardSuggestion",
        id: "cardSuggestion",
      },
    ],
    ARTI: [
      {
        no: 1,
        Label: "Arti List",
        name: "list",
        id: "list",
      },
      {
        no: 2,
        Label: "Arti Scheduler",
        name: "scheduler",
        id: "scheduler",
      },

    ],
  },
};
export default CONSTANTS;
