import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import CONSTANTS from '../../../../util/constant/CONSTANTS';

const ArtiUser = () => {
    const { id } = useParams();
    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="ARTI_USER"
                    tableTitle="Arti Users List"
                    getAPI={CONSTANTS.API.getArtiUser}
                    extraParams={`&artiId=${id}`}
                    extraResData={"rows"}
                    getData={(res) => {
                        console.log(res);
                        return res?.map((data) => {
                            return {
                                ...data,
                                no: `S${data?.id.toString().padStart(7, "0")}`,
                                name: data?.user?.name,
                                profilePic: data?.user?.profilePic,
                                mobile: data?.user?.mobile,
                                email: data?.user?.email,
                                date: moment.utc(data?.user?.createdAt).local().format('MMM DD, YYYY'),
                                createdAt: moment
                                    .utc(data?.createdAt)
                                    .local()
                                    .format("DD MMM, YYYY"),
                            };
                        });
                    }}

                    searchfilter
                    isSearch
                    searchAPI="/admin/arti?searchQuery="
                />
            </div>
        </>
    )
}

export default ArtiUser