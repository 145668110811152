import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import useHttp from '../../../../../../hooks/use-http';
import CONSTANTS from '../../../../../../util/constant/CONSTANTS';
import PageComponent from '../../../../../../component/common/Page-Component';

const StoryCardSuggestion = () => {
    const { id, episodes } = useParams();
    const API = useHttp();
    useEffect(() => {
        API.sendRequest(CONSTANTS.API.getCards, (res) => {
            const title = res?.data?.rows?.map((el) => ({
                Label: el?.title,
                value: el?.id,
                id: el?.id,
            }));
            // console.log(title);
            CONSTANTS.FORM_FIELD.STORY_CARD_SUGGESTION_MODAL.find(
                (el) => el?.id === "cardId"
            ).option = title;

        });
    }, []);
  return (
      <>
          <div>
              
              <PageComponent
                  extraEndPointData={`?storyEpisodeId=${episodes}`}
                  tableHeaders="STORY_CARD_SUGGESTION"
                  tableTitle=" Story Card Suggestion List"
                  getAPI={CONSTANTS.API.getStoryCardSuggestion}
                  extraResData="rows"
                  getData={(res) => {
                    //   console.log(res);
                      return res?.map((data) => {
                          return {
                              ...data,
                              no: `SC${data?.id.toString().padStart(7, "0")}`,
                              image: data?.card?.image,
                              title: data?.card?.title,
                              tagD: data?.card?.cardTags?.map((elp) => <p>{elp?.tag?.name}</p>),
                              godD: data?.card?.cardGods?.map((elp) => <p>{elp?.god?.name}</p>),
                          };
                      });
                  }}
                  payloadExtra={{ storyEpisodeId: +episodes }}
                  addData
                  modalButton="Add New Story Card Suggestion"
                  modalFields="STORY_CARD_SUGGESTION_MODAL"
                  addModalTitle="Add Story Card Suggestion"
                  addAPI={CONSTANTS.API.addStoryCardSuggestion}
                  viewData
                  viewFunction={() => { }}

                  deleteData
                  deleteAPI={CONSTANTS.API.deleteStoryCardSuggestion}
              />

      </div>
      
      
      </>
  )
}

export default StoryCardSuggestion