import React from 'react'
import { Column, Line } from '@ant-design/plots';

const DailyChart = ({ lastAllDaysUserAnalytics }) => {
    const config = {
        data: lastAllDaysUserAnalytics || [],
        padding: 'auto',
        xField: 'date',
        yField: 'count',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoRotate: false,
            },
        },
        slider: {
            start: 0,
            end: 0.9,
        },
        meta: {
            date: {
                alias: 'Date',
            },
            count: {
                alias: 'User',
            },
        },

    };

    return (
        <>

            <Column {...config} style={{ height: '250px' }} />

        </>
    )
}

export default React.memo(DailyChart)