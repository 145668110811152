import React from "react";
import { Card, Col, Row } from "antd";

const UserProfileDetails = (
  {
    bellPress,
    strick,
    language,
    god
  }
) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
        <Card size="small" className="py-2 px-4">
          <div>
            <p className="font-semibold text-lg text-slate-400">Bell Press</p>
            <p className=" text-xl font-medium">{bellPress}</p>
          </div>
        </Card>
      </Col>
      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
        <Card size="small" className="py-2 px-4">
          <div>
            <p className="font-semibold  text-lg text-slate-400">Strick </p>
            <p className=" text-xl font-medium">{strick}</p>
          </div>
        </Card>
      </Col>
      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
        <Card size="small" className="py-2 px-4">
          <div>
            <p className="font-semibold text-lg text-slate-400">Language</p>
            <p className=" text-xl font-medium">{language}</p>
          </div>
        </Card>
      </Col>
      <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
        <Card size="small" className="py-2 px-4">
          <div>
            <p className="font-semibold text-lg text-slate-400">God</p>
            <p className=" text-xl font-medium">{god}</p>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default UserProfileDetails;


