import React from 'react'
import PageComponent from '../../../component/common/Page-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';

const Language = () => {
    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="LANGUAGE"
                    tableTitle="Language List"
                    getAPI={CONSTANTS.API.getLanguage}
                    extraResData={"rows"}
                    getData={(res) => {
                        return res?.map((data) => {
                            console.log(res);
                            return {
                                ...data,
                                no: `T${data?.id.toString().padStart(7, "0")}`,
                                date: moment(data?.createdAt).format("DD MMM, YYYY"),
                            };
                        });
                    }}
                    modalButton="Add New Language"
                    modalFields="LANGUAGE_MODAL"
                    addModalTitle="Add Language Data"
                    addData
                    addAPI={CONSTANTS.API.addLanguage}
                    blockData
                    viewData
                    viewFunction={() => { }}
                    editData
                    editModalTitle="Edit Language Data"
                    editAPI={CONSTANTS.API.editLanguage}
                    deleteData
                    deleteAPI={CONSTANTS.API.deleteLanguage}
                />
            </div>

        </>
    )
}

export default Language