import React, { useEffect } from "react";
import CONSTANTS from "../../../../../../util/constant/CONSTANTS";
import PageComponent from "../../../../../../component/common/Page-Component";
import { useParams } from "react-router-dom";
import useHttp from "../../../../../../hooks/use-http";

const StoryEpisodeTranslation = () => {
    const { id, episodes } = useParams();
    const API = useHttp();
    useEffect(() => {
        API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
            const languages = res?.data?.rows?.map((el) => ({
                Label: el?.name,
                value: el?.id,
                id: el?.id,
            }));
            CONSTANTS.FORM_FIELD.ADD_CARD_MODAL_INNER.find(
                (el) => el?.id === "languageId"
            ).option = languages;
            CONSTANTS.FORM_FIELD.QUESTION.find(
                (el) => el?.id === "languageId"
            ).option = languages;
            CONSTANTS.FORM_FIELD.EPISODE_STORY_TRANSLATIONS.find(
                (el) => el?.id === "languageId"
            ).option = languages;

            CONSTANTS.FORM_FIELD.STORY_TRANSLATIONS.find(
                (el) => el?.id === "languageId"
            ).option = languages;
        });
    }, []);

    return (
        <>
            <PageComponent
                extraParams={`&storyEpisodeId=${episodes}`}
                tableHeaders="CARD_TRANSLATION"
                tableTitle="Episode Story Translation List"
                getAPI={CONSTANTS.API.getStoryEpisodeTranslations}
                extraResData="rows"
                getData={(res) => {
                    // console.log(res);
                    return res?.map((data) => {
                        // console.log(data, "translations");
                        return {
                            ...data,
                            no: `ST${data?.id.toString().padStart(7, "0")}`,
                            language: data?.language?.name,
                        };
                    });
                }}
                payloadExtra={{ storyEpisodeId: episodes }}
                addData
                modalButton="Add New Story Episode Translation"
                modalFields="EPISODE_STORY_TRANSLATIONS"
                addModalTitle="Add Episode Story Translation"
                addAPI={CONSTANTS.API.addStoryEpisodeTranslation}
                viewData
                viewFunction={() => { }}
                blockData
                editModalFields="EPISODE_STORY_TRANSLATIONS"
                editModalTitle="Edit Episode Story Translation"
                editData
                editAPI={CONSTANTS.API.editStoryEpisodeTranslation}
                deleteData
                deleteAPI={CONSTANTS.API.deleteStoryEpisodeTranslation}
            />
        </>
    );
};

export default StoryEpisodeTranslation;
