import { Card, Tabs } from 'antd';
import React from 'react'
import CONSTANTS from '../../../../../util/constant/CONSTANTS';
import StoryEpisodeTranslation from './tabs/story-episode-translation';
import StoryEpisodeSuggestion from './tabs/episode-suggestion';
import StoryCardSuggestion from './tabs/card-suggestion';

const StoryEpisodeDetails = () => {
     const TabsView = {
    translation: <StoryEpisodeTranslation />,
    episodeSuggestion: <StoryEpisodeSuggestion />,
    cardSuggestion: <StoryCardSuggestion />,
  };
  return (
      <>
          <Card className="mt-10">
              <Tabs
                  className="p-0 m-0"
                  defaultActiveKey="1"
                  size="middle"
                  type="card"
                  items={CONSTANTS.TAB_MENU.STORY_EPISODE.map((ele) => {
                      return {
                          label: `${ele.Label}`,
                          key: `${ele.id}`,
                          children: TabsView[ele?.id],
                      };
                  })}
              />
          </Card>
    </>
  )
}

export default StoryEpisodeDetails