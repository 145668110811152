import React, { useEffect } from "react";
import useHttp from "../../../../hooks/use-http";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import PageComponent from "../../../../component/common/Page-Component";

const StoryTranslation = () => {
  const { id } = useParams();
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
      const languages = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL_INNER.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.QUESTION.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.STORY_TRANSLATIONS.find(
        (el) => el?.id === "languageId"
      ).option = languages;
    });
  }, []);

  return (
    <>
      <PageComponent
        extraParams={`&storyId=${id}`}
        tableHeaders="CARD_TRANSLATION"
        tableTitle="Story Translation List"
        getAPI={CONSTANTS.API.getStoryTranslations}
        extraResData="rows"
        getData={(res) => {
          console.log(res);
          return res?.map((data) => {
            console.log(data, "translations");
            return {
              ...data,
              no: `ST${data?.id.toString().padStart(7, "0")}`,
              shortDescription: data?.sortDescription,
              language: data?.language?.name,
            };
          });
        }}
        payloadExtra={{ storyId: id }}
        addData
        modalButton="Add New Translation"
        modalFields="STORY_TRANSLATIONS"
        addModalTitle="Add Story Translation"
        addAPI={CONSTANTS.API.addStoryTranslation}
        viewData
        viewFunction={() => { }}
        blockData
        editModalFields="STORY_TRANSLATIONS"
        editModalTitle="Edit Story Translation "
        editData
        editAPI={CONSTANTS.API.editStoryTranslation}
        deleteData
        deleteAPI={CONSTANTS.API.deleteStoryTranslation}
      />
    </>
  );
};

export default StoryTranslation;
