import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import moment from "moment";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";

const Darshan = () => {
  const [godList, setGodList] = useState([]);

  const navigate = useNavigate();

  const userDetail = (id) => {
    navigate(`/app/darshan/darshan-user/${id}`);
  };

  CONSTANTS.FORM_FIELD.DARSHAN_MODAL[1].option = godList;
  CONSTANTS.FORM_FIELD.EDIT_DARSHAN_MODAL[1].option = godList;

  const api = useHttp();
  useEffect(() => {
    const GOD_LIST_API = { ...CONSTANTS.API.getGods };
    api.sendRequest(GOD_LIST_API, (res) => {
      setGodList(
        res?.data?.rows?.map((data) => {
          return {
            id: data.id,
            value: data.id,
            Label: data.name,
          };
        })
      );
    });
  }, []);
  return (
    <>
      <PageComponent
        tableHeaders="DARSHAN"
        tableTitle="Darashan List"
        getAPI={CONSTANTS.API.getDarshans}
        extraResData={"rows"}
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `D${data?.id.toString().padStart(7, "0")}`,
              boostAt: moment.utc(data?.boostAt).local().format("DD MMM, YYYY"),
              god: data?.darashanGods?.map((ele) => ele?.god?.name).join(","),
              name: data?.name || "no data found",
            };
          });
        }}
        modalButton="Add New Darashan"
        modalFields="DARSHAN_MODAL"
        addModalTitle="Add Darashan Data"
        formData
        addAPI={CONSTANTS.API.addDarshan}
        blockData
        viewData
        viewFunction={userDetail}
        editformData
        editModalFields="EDIT_DARSHAN_MODAL"
        editModalTitle="Edit Darashan Data"
        editAPI={CONSTANTS.API.editDarshan}
        deleteData
        deleteAPI={CONSTANTS.API.deleteDarshan}
        searchfilter
        isSearch
        searchAPI="/admin/darashan?searchQuery="
      />
    </>
  );
};

export default Darshan;
