import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import PageComponent from '../../../../../../component/common/Page-Component';
import CONSTANTS from '../../../../../../util/constant/CONSTANTS';
import useHttp from '../../../../../../hooks/use-http';

const StoryEpisodeSuggestion = () => {
    const { id, episodes } = useParams();
    const API = useHttp();
    useEffect(() => {
        API.sendRequest(CONSTANTS.API.getStoryEpisodes, (res) => {
            const title = res?.data?.rows?.map((el) => ({
                Label: el?.title,
                value: el?.id,
                id: el?.id,
            }));
            // console.log(title);
            CONSTANTS.FORM_FIELD.STORY_EPISODE_SUGGESTION_MODAL.find(
                (el) => el?.id === "suggestionStoryEpisodeId"
            ).option = title;

        });
    }, []);
    return (
        <>

            <PageComponent
                extraEndPointData={`?storyEpisodeId=${episodes}`}
                tableHeaders="STORY_EPISODE_SUGGESTION"
                tableTitle=" Story Episode Suggestion List"
                getAPI={CONSTANTS.API.getStoryEpisodeSuggestion}
                extraResData="rows"
                getData={(res) => {
                    // console.log(res);
                    return res?.map((data) => {
                        return {
                            ...data,
                            no: `SE${data?.id.toString().padStart(7, "0")}`,
                            image: data?.suggestionStoryEpisode?.image,
                            title: data?.suggestionStoryEpisode?.title,
                            tagD: data?.suggestionStoryEpisode?.storyEpisodeTags?.map((elp) => <p>{elp?.tag?.name}</p>),
                        };
                    });
                }}
                payloadExtra={{ storyEpisodeId: +episodes }}
                addData
                modalButton="Add New Story Episode Suggestion"
                modalFields="STORY_EPISODE_SUGGESTION_MODAL"
                addModalTitle="Add Story Episode Suggestion"
                addAPI={CONSTANTS.API.addStoryEpisodeSuggestion}
                viewData
                viewFunction={() => { }}

                deleteData
                deleteAPI={CONSTANTS.API.deleteStoryEpisodeSuggestion}
            />


        </>
    )
}

export default StoryEpisodeSuggestion