import React from 'react'
import { Column } from '@ant-design/plots';

const ColumnChart = ({ lastAllYearsUserAnalytics }) => {
    // console.log(lastAllYearsUserAnalytics);
    const config = {
        data: lastAllYearsUserAnalytics || [],
        xField: 'year',
        yField: 'count',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        slider: {
            start: 0,
            end: 0.9,
        },
        meta: {
            year: {
                alias: 'Year',
            },
            count: {
                alias: 'User',
            },
        },
    };
    return (
        <>

            <Column {...config} style={{ height: '250px' }} />
        </>
    )
}

export default React.memo(ColumnChart)