import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import Registration from "../page/user/Registration";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/analytics";
import { appRoot } from "./constant/CONSTANTS";
import ForgetPassword from "../page/user/Registration/forgetPass";
import Users from "../page/app/users";
import UserDetail from "../page/app/users/user-detail";
import Analytics from "../page/app/analytics";
import Darshan from "../page/app/darshan";
import Story from "../page/app/story";
import Cards from "../page/app/cards";
import Tags from "../page/app/tags";
import God from "../page/app/god";
import Configuration from "../page/app/configuration";
import CardDetails from "../page/app/cards/Card-Details";
import StoryDetails from "../page/app/story/Story-Deatils";
import Arti from "../page/app/arti";
import ArtiUser from "../page/app/arti/tabs/arti-user";
import DarshanUser from "../page/app/darshan/darshan-user";
import StoryEpisodeDetails from "../page/app/story/tabs/Story-Epidode/story-episode-details";
import Language from "../page/app/language";
import ArtiDetail from "../page/app/arti";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  ANALYTICS: "/analytics",

  DARSHAN: "/darshan",
  DARSHAN_USER: "/darshan/darshan-user/:id",
  USERS: "/users",
  USERS_DETAIL: "/users/:id",
  STORY: "/story",
  STORY_DETAIL: "/story/:id",
  STORY_EPISODE_DETAIL: "/story/:id/:episodes",

  CARDS: "/cards",
  LANGUAGE: "/language",
  CARDS_DETAIL: "/cards/:id",
  TAGS: "/tags",
  GOD: "/god",
  ARTI: "/arti",
  ARTI_USER: "/arti/arti-user/:id",
  CONFIGURATION: "/configuration",
};

const LOGIN_ROUTES = [
  // {
  //   path: "/",
  //   element: <SidePage />,
  //   errorElement: <Error />,
  //   children: [
  //     {
  //       index: true,
  //       element: <LogIn />,
  //     },
  //     {
  //       path: "registration",
  //       element: <Registration />,
  //     },
  //   ],
  // },
  {
    index: true,
    element: <LogIn />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <Error />,
  },
];

const ALL_ROUTES = [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Navigate to={`${appRoot}${ROUTES.ANALYTICS}`} />,
      },
      {
        path: `${appRoot}${ROUTES.ANALYTICS}`,
        element: <Analytics />,
      },
      {
        path: `${appRoot}${ROUTES.DARSHAN}`,
        element: <Darshan />,
      },
      {
        path: `${appRoot}${ROUTES.DARSHAN_USER}`,
        element: <DarshanUser />,
      },
      {
        path: `${appRoot}${ROUTES.USERS}`,
        element: <Users />,
      },
      {
        path: `${appRoot}${ROUTES.USERS_DETAIL}`,
        element: <UserDetail />,
      },
      {
        path: `${appRoot}${ROUTES.STORY}`,
        element: <Story />,
      },
      {
        path: `${appRoot}${ROUTES.STORY_DETAIL}`,
        element: <StoryDetails />,
      },
      {
        path: `${appRoot}${ROUTES.STORY_EPISODE_DETAIL}`,
        element: <StoryEpisodeDetails />,
      },
      {
        path: `${appRoot}${ROUTES.CARDS}`,
        element: <Cards />,
      },
      {
        path: `${appRoot}${ROUTES.CARDS_DETAIL}`,
        element: <CardDetails />,
      },
      {
        path: `${appRoot}${ROUTES.TAGS}`,
        element: <Tags />,
      },
      {
        path: `${appRoot}${ROUTES.LANGUAGE}`,
        element: <Language />,
      },

      {
        path: `${appRoot}${ROUTES.GOD}`,
        element: <God />,
      },
      {
        path: `${appRoot}${ROUTES.ARTI}`,
        element: <ArtiDetail />,
      },
      {
        path: `${appRoot}${ROUTES.ARTI_USER}`,
        element: <ArtiUser />,
      },

      {
        path: `${appRoot}${ROUTES.CONFIGURATION}`,
        element: <Configuration />,
      },

      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}`,
      //   element: (
      //     <Navigate
      //       to={`${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_DETAILS}`}
      //     />
      //   ),
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_DETAILS}`,
      //   element: <Inventory />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_MOVEHISTORY}`,
      //   element: <MoveHistory />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_EQUIPMENT}`,
      //   element: <Equipment />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_EQUIPMENT_DETAILS}`,
      //   element: <EquipmentDetailPage />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_TRANSFER}`,
      //   element: <Transfer />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_PURCHASE}`,
      //   element: <PurchaseRequest />,
      // },
      //
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  {
    path: "*",
    element: <Navigate to="/error" />,
  },
];

export default ALL_ROUTES;
