import { Card, Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";

const Configuration = () => {
  const [setting, setSetting] = useState();
  const [refresh, setRefresh] = useState();
  const API = useHttp();
  useEffect(() => {
    API.sendRequest({ type: "GET", endpoint: "/admin/appConfig" }, (res) => {
      setSetting(res?.data?.rows?.[0]);
    });
  }, [refresh]);

  return (
    <Card className="mt-10">
      <h2>Configuration</h2>
      <div style={{ marginTop: "20px" }}>
        {[
          { title: "App Maintanance", id: "appInMaintenance" },
          { title: "Force Update", id: "forceUpdate" },

          { title: "Soft Update", id: "softUpdate" },
        ].map((el) => (
          <div
            id={el?.id}
            key={el?.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
              alignItems: "center",
            }}
          >
            <h3>{el?.title}</h3>
            <Switch
              checked={setting?.[el?.id]}
              onChange={(e) => {
                API.sendRequest(
                  {
                    type: "PATCH",
                    endpoint: `/admin/appConfig/${setting?.id}`,
                  },
                  () => {
                    setRefresh(!refresh);
                  },
                  { [el?.id]: e }
                );
              }}
            />
          </div>
        ))}
      </div>
      <div style={{ marginTop: "20px" }}>
        {[
          { title: "android Version Code", id: "androidVersionCode" },
          { title: "ios Version Code", id: "iosVersionCode" },
        ].map((el) => (
          <div
            id={el?.id}
            key={el?.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
              alignItems: "center",
            }}
          >
            <h3>{el?.title}</h3>
            <div style={{ height: "30px", width: "100px" }}>
              <Input
                id={el?.id}
                type="text"
                style={{ textAlign: "center" }}
                placeholder={setting?.[el?.id] || "Version Code"}
                onBlurCapture={(e) => {
                  API.sendRequest(
                    {
                      type: "PATCH",
                      endpoint: `/admin/appConfig/${setting?.id}`,
                    },
                    () => {
                      setRefresh(!refresh);
                    },
                    { [el?.id]: e.target.value }
                  );
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Configuration;
