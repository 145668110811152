import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tabs } from "antd";

import UserProfile from "./user-profile";
import UserProfileDetails from "./user-profile-detail";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import Story from "./tabs/Story";
import Read from "./tabs/Read";
import Darshan from "./tabs/Darshan";
import ListenArti from "./tabs/Listen-arti";
import Feedback from "./tabs/Feedback";
import Family from "./tabs/family";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import useHttp from "../../../hooks/use-http";
import moment from "moment";

const UserDetail = () => {
  const [UserDetail, setUserDetail] = useState({});
  const [refresh, setRefresh] = useState(false)
  const { id } = useParams();
  const api = useHttp();
  const dataId = id;
  const menus =
  {
    story: <Story />,
    read: <Read />,
    darshan: <Darshan />,
    arti: <ListenArti />,
    family: <Family />,
    feedback: <Feedback />,
  };
  // console.log(UserDetail);
  useEffect(() => {
    const GET_ONE_USER_API = apiGenerator(CONSTANTS.API.getOneUsers, { dataId })
    api.sendRequest(GET_ONE_USER_API, (res) => {
      setUserDetail({
        ...res?.data?.user,
        strick: +res?.data?.user?.streak,
        bellPress: +res?.data?.bellPress,
        language: res?.data?.user?.language?.name,
        god: res?.data?.user?.userGods?.map(ele => ele?.god?.name)?.join(','),
        mobile: `+${res?.data?.user?.countryCode} ${res?.data?.user?.mobile}`,
        email: res?.data?.user?.email,
        name: res?.data?.user?.name,
        gender: res?.data?.user?.gender,
        profilePic: res?.data?.user?.profilePic,
        isBlocked: res?.data?.user?.isBlocked,
        dob: moment(res?.data?.user?.dob).format('MMM DD, YYYY'),
      })
    })
  }, [refresh])

  return (
    <>
      <div className="mt-10">
        <Row gutter={[16, 16]}>
          <Col span={7} xs={24} sm={24} md={24} lg={7} xl={7}>
            <UserProfile {...UserDetail} setRefresh={setRefresh} />
          </Col>
          <Col span={17} xs={24} sm={24} md={24} lg={17} xl={17}>
            <UserProfileDetails {...UserDetail} />
          </Col>
        </Row>
        <Card className="mt-10">
          <Tabs
            className="p-0 m-0"
            defaultActiveKey="1"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.USER.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: menus[ele?.id],
              };
            })}
          />
        </Card>
      </div>
    </>
  );
};

export default UserDetail;
