import React from 'react'
import { useParams } from 'react-router-dom';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { BsFillStarFill } from "react-icons/bs";
import PageComponent from '../../../../component/common/Page-Component';

const Feedback = () => {
  const { id } = useParams();
  return (
    <>

      <div>
        <PageComponent
          tableHeaders="USER_FEEDBACK"
          tableTitle="User Feedback List"
          getAPI={CONSTANTS.API.getUserFeedback}
          extraParams={`&userId=${id}`}
          extraResData={"rows"}
          getData={(res) => {
            // console.log(res);
            return res?.map((data) => {
              return {
                ...data,
                no: `UFe${data?.id.toString().padStart(7, "0")}`,
                rating: <div> {data?.rating} <BsFillStarFill  /></div>,



              };
            });
          }}

        />
      </div>
    </>
  )
}

export default Feedback