import React from "react";
import useHttp from "../../../../../hooks/use-http";
import { useNavigate, useParams } from "react-router-dom";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import PageComponent from "../../../../../component/common/Page-Component";

const StoryEpisode = () => {
  const API = useHttp();
  const { id } = useParams();

  const navigate = useNavigate();

  const EpisodeDeatil = (episode) => {
    navigate(`/app/story/${id}/${episode}`);
  };
  React.useEffect(() => {
    API.sendRequest(CONSTANTS.API.getTags, (res) => {
      const tags = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.NEW_STORY_EPISODE.find(
        (el) => el?.id === "tag"
      ).option = tags;
      CONSTANTS.FORM_FIELD.EDIT_STORY_EPISODE.find(
        (el) => el?.id === "tag"
      ).option = tags;
    });

    API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
      const languages = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL_INNER.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.QUESTION.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.STORY_TRANSLATIONS.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.EPISODE_STORY_TRANSLATIONS.find(
        (el) => el?.id === "languageId"
      ).option = languages;
    });
  }, []);
  return (
    <div>
      <PageComponent
        extraParams={`&storyId=${id}`}
        tableHeaders="STORY_EPISODE"
        tableTitle="Story Episode List"
        getAPI={CONSTANTS.API.getStoryEpisodes}
        extraResData={"rows"}
        getData={(res) => {
          console.log(res);
          return res?.map((data) => {
            return {
              ...data,
              no: `S${data?.id.toString().padStart(7, "0")}`,

              tag: data?.storyEpisodeTags?.map((ele) => ele?.tag?.id),

              tagD: data?.storyEpisodeTags?.map((elp) => (
                <p>{`#${elp?.tag?.name}`}</p>
              )),
            };
          });
        }}
        payloadExtra={{ storyId: id }}
        formData
        modalButton="Add New Episode"
        modalFields="NEW_STORY_EPISODE"
        addModalTitle="Add Story Episode Data"
        addAPI={CONSTANTS.API.addStoryEpisode}
        blockData
        viewData
        viewFunction={EpisodeDeatil}
        editformData
        editModalFields="EDIT_STORY_EPISODE"
        editModalTitle="Edit Story Episode Data"
        editAPI={CONSTANTS.API.editStoryEpisode}
        deleteData
        deleteAPI={CONSTANTS.API.deleteStoryEpisode}
        searchfilter
        isSearch
        searchAPI={`/admin/storyEpisode?storyId=${id}&searchQuery=`}
      />
    </div>
  );
};

export default StoryEpisode;
