import React from 'react'
import PageComponent from '../../../component/common/Page-Component';
import moment from 'moment';
import CONSTANTS from '../../../util/constant/CONSTANTS';

const Tags = () => {
  return (
    <>
      <PageComponent
        tableHeaders="TAG"
        tableTitle="Tag List"
        getAPI={CONSTANTS.API.getTags}
        extraResData={"rows"}
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `T${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("DD MMM, YYYY"),
            };
          });
        }}
        modalButton="Add New Tag"
        modalFields="TAG_MODAL"
        addModalTitle="Add Tag Data"
        addData
        addAPI={CONSTANTS.API.addTag}
        blockData
        viewData
        viewFunction={() => { }}
        editData
        editModalTitle="Edit Tag Data"
        editAPI={CONSTANTS.API.editTag}
        deleteData
        deleteAPI={CONSTANTS.API.deleteTag}
      />
    </>
  );
}

export default Tags