import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
const Cards = () => {
  const navigate = useNavigate();

  const userDetail = (id) => {
    navigate(`/app/cards/${id}`);
  };
  const API = useHttp();
  React.useEffect(() => {
    API.sendRequest(CONSTANTS.API.getTags, (res) => {
      const tags = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.EDIT_CARD_MODAL.find(
        (el) => el?.id === "tag"
      ).option = tags;
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL.find(
        (el) => el?.id === "tag"
      ).option = tags;
    });
    API.sendRequest(CONSTANTS.API.getGods, (res) => {
      const tags = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.EDIT_CARD_MODAL.find(
        (el) => el?.id === "god"
      ).option = tags;
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL.find(
        (el) => el?.id === "god"
      ).option = tags;
    });
    API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
      const languages = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL_INNER.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.QUESTION.find(
        (el) => el?.id === "languageId"
      ).option = languages;
    });
  }, []);
  return (
    <>
      <PageComponent
        tableHeaders="CARD_LIST"
        tableTitle="Cards List"
        getAPI={CONSTANTS.API.getCards}
        extraResData={"rows"}
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `C${data?.id.toString().padStart(7, "0")}`,
              tagD: data?.cardTags?.map((elp) => <p>{elp?.tag?.name}</p>),
              tag: data?.cardTags?.map((elp) => elp?.tag?.id),
              godD: data?.cardGods?.map((elp) => <p>{elp?.god?.name}</p>),
              god: data?.cardGods?.map((elp) => elp?.god?.id),
            };
          });
        }}
        formData
        modalButton="Add New Card"
        modalFields="ADD_CARD_MODAL"
        addModalTitle="Add Card Data"
        addAPI={CONSTANTS.API.addCard}
        viewData
        viewFunction={userDetail}
        editformData
        blockData
        editModalFields="EDIT_CARD_MODAL"
        editModalTitle="Edit Card Data"
        editAPI={CONSTANTS.API.editCard}
        deleteData
        deleteAPI={CONSTANTS.API.deleteCard}
        searchfilter
        isSearch
        searchAPI="/admin/card?searchQuery="
      />
    </>
  );
};

export default Cards;
