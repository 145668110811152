import React from 'react'
import { Column } from '@ant-design/plots';

const MonthChart = ({ lastAllMonthsUserAnalytics }) => {
    let outputArray = []
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    for (let month = 1; month <= 12; month++) {
        const existingObject = lastAllMonthsUserAnalytics?.find(item => item?.month === month);
        const count = existingObject ? existingObject.count : 0;
        const monthName = monthNames[month - 1];

        outputArray.push({ month: monthName, count });
    }

    // console.log(outputArray);

    const config = {
        data: outputArray || [],
        xField: 'month',
        yField: 'count',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: false,
            },
        },

        meta: {
            month: {
                alias: 'month',
            },
            count: {
                alias: 'User',
            },
        },


    };
    return (
        <>
            <div className=''>

                <Column  {...config} style={{ height: '250px' }} />
            </div>
        </>
    )
}

export default React.memo(MonthChart)