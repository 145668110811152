import { Card, Tabs } from 'antd';
import React from 'react'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import Arti from './tabs/arti';
import ArtiScheduler from './tabs/scheduler';

const ArtiDetail = () => {
    const TabsView = {
        list: <Arti />,
        scheduler: <ArtiScheduler />,
    };
    return (
        <>
            <Card className="mt-10">
                <Tabs
                    className="p-0 m-0"
                    defaultActiveKey="1"
                    size="middle"
                    type="card"
                    items={CONSTANTS.TAB_MENU.ARTI.map((ele) => {
                        return {
                            label: `${ele.Label}`,
                            key: `${ele.id}`,
                            children: TabsView[ele?.id],
                        };
                    })}
                />
            </Card>

        </>
    )
}

export default ArtiDetail