import React, { useEffect, useState } from "react";
import { Card, Col, DatePicker, Row } from "antd";
import dayjs from "dayjs";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";

import ColumnChart from "./column-chart";
import DailyChart from "./daily-chart";
import moment from "moment";
import MonthChart from "./month-chart";

const Analytics = () => {
  const [analysis, setAnalysis] = useState({})
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null
  })
  const API = useHttp();
  const { RangePicker } = DatePicker;

  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]



  // console.log(analysis);
  useEffect(() => {
    const GET_ANALYSIS_API = { ...CONSTANTS.API.getAnalysis }
    if ((selectedDates?.startDate !== null && selectedDates?.endDate !== null)) {
      GET_ANALYSIS_API.endpoint = `${GET_ANALYSIS_API.endpoint}?startDate=${selectedDates?.startDate}&endDate=${selectedDates?.endDate}`
    }
    API.sendRequest(GET_ANALYSIS_API, (res) => {
      setAnalysis(prev => {
        return {
          ...prev,
          ...res?.data,
        }
      })
    });
  }, [selectedDates]);
  return (

    <div className="">
      <div className="my-5">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <p className="font-medium pb-5">Date Range</p>
              <RangePicker
                className="w-3/4"
                onChange={(e) => {
                  if (e) {
                    setSelectedDates({
                      startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
                      endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
                    });
                  }
                  else {
                    setSelectedDates({
                      startDate: null,
                      endDate: null,
                    });
                  }
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">Total User</p>
              <p className="text-xl font-medium">{analysis?.user}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">Total Darashan</p>
              <p className="text-xl font-medium">{analysis?.darashan}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">Total Unique Darashan User</p>
              <p className="text-xl font-medium">{analysis?.uniqueUserOfDarashan}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Story
              </p>
              <p className="text-xl font-medium">{analysis?.totalStory}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Reads
              </p>
              <p className="text-xl font-medium">{analysis?.totalReads}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Reads User
              </p>
              <p className="text-xl font-medium">{analysis?.totalReadUser}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Unique Reads User
              </p>
              <p className="text-xl font-medium">{analysis?.totalUniqueReadUser}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Watch Episode User
              </p>
              <p className="text-xl font-medium">{analysis?.totalWatchEpisodeUser}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Unique Watch Episode User
              </p>
              <p className="text-xl font-medium">{analysis?.totalWatchEpisodeUniqueUser}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Total Story Episode
              </p>
              <p className="text-xl font-medium">{analysis?.totalStoryEpisode}</p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="my-5" gutter={[16, 16]}>
        <Col span={20} xs={24} sm={24} md={20} lg={20} xl={20}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                User Statics (Year)
              </p>
              <div className="">
                <ColumnChart {...analysis} />
              </div>
            </div>
          </Card>
        </Col>
        <Col span={20} xs={24} sm={24} md={20} lg={20} xl={20}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                User Statics (Daily)
              </p>
              <div className="">
                <DailyChart {...analysis} />
              </div>
            </div>
          </Card>
        </Col>
        <Col span={20} xs={24} sm={24} md={20} lg={20} xl={20}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                User Statics (Month)
              </p>
              <div className="">
                <MonthChart {...analysis} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
