import React from 'react'
import { useParams } from 'react-router-dom';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import PageComponent from '../../../../component/common/Page-Component';

const Read = () => {
  const { id } = useParams();
  return (
    <>
      <div>
        <PageComponent
          tableHeaders="USER_READ"
          tableTitle="Read Users List"
          getAPI={CONSTANTS.API.getUserCard}
          extraParams={`&userId=${id}`}
          extraResData={"rows"}
          getData={(res) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `UR${data?.id.toString().padStart(7, "0")}`,
                profilePic: data?.card?.image,
                title: data?.card?.title,
                god: data?.card?.cardGods?.map((elp) => (
                  <p>{elp?.god?.name}</p>
                )),
                tag: data?.card?.cardTags?.map((elp) => (
                  <p>{elp?.tag?.name}</p>
                )),

              };
            });
          }}

        />
      </div>
    </>
  )
}

export default Read