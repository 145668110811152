import { Card, Col, Image, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import CardTranslation from "./tabs/Card-Translation";
import CardQuestion from "./tabs/Card-Question";
import imageSrc from "../../../asset/image/image 2.png";
import useHttp from "../../../hooks/use-http";
import { useParams } from "react-router-dom";
const CardDetails = () => {
  const [CardDetail, setCardDetail] = useState({});
  const { id } = useParams();
  const API = useHttp();
  const TabsView = {
    cardTranslation: <CardTranslation />,
    cardQuestion: <CardQuestion />,
  };
  useEffect(() => {
    const CardAPI = { ...CONSTANTS.API.getCardById };
    CardAPI.endpoint = CardAPI.endpoint.replace(":dataId", id);
    API.sendRequest(CardAPI, (res) => {
      setCardDetail(res?.data);
    });
  }, []);
  return (
    <>
      <div className="mt-10">
        <Row gutter={[16, 16]}>
          <Col span={7} xs={24} sm={24} md={24} lg={7} xl={7}>
            <Card className="h-30">
              <div className="flex ml-5">
                <Image
                  width={80}
                  height={80}
                  className="rounded-full"
                  preview={false}
                  src={CardDetail?.image || imageSrc}
                  alt="Images is Not Availabel"
                />
                <p className="font-medium mt-7 px-3 text-2xl">
                  {CardDetail?.title}
                </p>
              </div>
            </Card>
          </Col>
        </Row>
        <Card className="mt-10">
          <Tabs
            className="p-0 m-0"
            defaultActiveKey="1"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.CARD.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: TabsView[ele?.id],
              };
            })}
          />
        </Card>
      </div>
    </>
  );
};

export default CardDetails;
