import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const Darshan = () => {
  const { id } = useParams();
  return (
    <>
      <div>
        <PageComponent
          tableHeaders="USER_DARSHAN"
          tableTitle="Darashan Users List"
          getAPI={CONSTANTS.API.getUserDarshan}
          extraParams={`&userId=${id}`}
          extraResData={"rows"}
          getData={(res) => {
            // console.log(res);
            return res?.map((data) => {
              return {
                ...data,
                no: `UD${data?.id.toString().padStart(7, "0")}`,
                profilePic: data?.darashan?.image,
                name: data?.darashan?.name,
                boostAt: moment.utc(data?.darashan?.boostAt).local().format('MMM DD, YYYY'),
                god: data?.darashan?.darashanGods?.map((elp) => (
                  <p>{elp?.god?.name}</p>
                )),


              };
            });
          }}

        />
      </div>
    </>
  )
}

export default Darshan