import React from 'react'
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import PageComponent from '../../../../component/common/Page-Component';
const Family = () => {
  const { id } = useParams();
  return (
    <>
    
      <div>
        <PageComponent
          tableHeaders="USER_FAMILY"
          tableTitle="Family List"
          getAPI={CONSTANTS.API.getUserFamily}
          extraParams={`&userId=${id}`}
          extraResData={"rows"}
          getData={(res) => {
            // console.log(res);
            return res?.map((data) => {
              return {
                ...data,
                no: `UF${data?.id.toString().padStart(7, "0")}`,
                profilePic: data?.image,
                dob: moment.utc(data?.dob).local().format('MMM DD, YYYY'),
                createdAt: moment.utc(data?.createdAt).local().format('MMM DD, YYYY'),
               


              };
            });
          }}

        />
      </div>
    </>
  )
}

export default Family