import React from 'react'
import { useParams } from 'react-router-dom';
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';

const ListenArti = () => {
  const { id } = useParams();
  return (
    <>
      <div>
        <PageComponent
          tableHeaders="USER_ARTI"
          tableTitle="Arti Users List"
          getAPI={CONSTANTS.API.getArtiUser}
          extraParams={`&userId=${id}`}
          extraResData={"rows"}
          getData={(res) => {
            // console.log(res);
            return res?.map((data) => {
              return {
                ...data,
                no: `S${data?.id.toString().padStart(7, "0")}`,
                track: data?.arti?.previewTrack,
                title: data?.arti?.title,
                profilePic: data?.arti?.image,
                tag: data?.arti?.artiTags?.map((elp) => <p>{`#${elp?.tag?.name}`}</p>),
                god: data?.arti?.artiGods?.map((elp) => <p>{elp?.god?.name}</p>),
                language: data?.arti?.artiLanguages?.map((elp) => <p>{elp?.language?.name}</p>),
              };
            });
          }}
         
        />
      </div>
    </>
  )
}

export default ListenArti