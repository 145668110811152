import React, { useEffect } from "react";
import PageComponent from "../../../../component/common/Page-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import useHttp from "../../../../hooks/use-http";

const CardTranslation = () => {
  const { id } = useParams();
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
      const languages = res?.data?.rows?.map((el) => ({
        Label: el?.name,
        value: el?.id,
        id: el?.id,
      }));
      CONSTANTS.FORM_FIELD.ADD_CARD_MODAL_INNER.find(
        (el) => el?.id === "languageId"
      ).option = languages;
      CONSTANTS.FORM_FIELD.QUESTION.find(
        (el) => el?.id === "languageId"
      ).option = languages;
    });
  }, []);

  return (
    <>
      <PageComponent
        extraParams={`&cardId=${id}`}
        tableHeaders="CARD_TRANSLATION"
        tableTitle="Card Translation List"
        getAPI={CONSTANTS.API.getCardTranslations}
        extraResData="rows"
        getData={(res) => {
          // console.log(res);
          return res?.map((data) => {
            return {
              ...data,
              no: `T${data?.id.toString().padStart(7, "0")}`,
              language: data?.language?.name,
            };
          });
        }}
        payloadExtra={{ cardId: id }}
        addData
        modalButton="Add New Translation"
        modalFields="ADD_CARD_MODAL_INNER"
        addModalTitle="Add Card Translation Data"
        addAPI={CONSTANTS.API.addCardTranslation}
        viewData
        viewFunction={() => { }}
        blockData
        editModalFields="ADD_CARD_MODAL_INNER"
        editModalTitle="Edit Translation "
        editData
        editAPI={CONSTANTS.API.editCardTranslation}
        deleteData
        deleteAPI={CONSTANTS.API.deleteCardTranslation}
      />
    </>
  );
};

export default CardTranslation;
