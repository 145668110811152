import React, { useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import useHttp from "../../../hooks/use-http";

const Users = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null)
  const [generalNotification, setGeneralNotification] = useState(false)

  const api = useHttp();


  const userDetail = (id) => {
    navigate(`/app/users/${id}`);
  };

// Sent Personl Notification
  const sentNotification = (value) => {

    const payload = {
      ...value,
      userId: +userId
    }


    const SENT_NOTIFICATION_API = { ...CONSTANTS.API.sendUserPersonalNotification };

    api.sendRequest(SENT_NOTIFICATION_API, () => {
      setUserId(null)
    }, payload, 'Notification Sent!!!')


  }

  // Sent General Notification
  const sentGeneralNotification = (value) => {
    const payload = { ...value }
    
    // console.log(payload);

    const GENERAL_NOTIFICATION_API = { ...CONSTANTS.API.sendUserGeneralNotification }
    
    api.sendRequest(GENERAL_NOTIFICATION_API, () => {
      setGeneralNotification(prev => !prev)
    },payload,'Notification Sent to All the Users!!!')

  }

  return (
    <>
      <div>
        <PageComponent
          tableHeaders="USERS"
          tableTitle="Users List"
          getAPI={CONSTANTS.API.getUsers}
          extraResData="rows"
          getData={(res) => {
            // console.log(res);
            return res?.map((data) => {
              return {
                ...data,
                no: `U${data?.id.toString().padStart(7, "0")}`,
                date: moment(data?.createdAt).format("DD MMM, YYYY"),
                notification: {
                  id: data?.id,
                  onClick: (id) => {
                    setUserId(id)
                  }
                }
              };
            });
          }}
          viewData
          viewFunction={userDetail}
          blockData
          editAPI={CONSTANTS.API.editUsers}
          searchfilter
          isSearch
          searchAPI="/admin/users?searchQuery="
          getNotify={() => {
            setGeneralNotification(prev => !prev)
          }}
        />
      </div>
      <ModalFormCreator
        open={userId !== null}
        onCreate={sentNotification}
        onCancel={() => {
          setUserId(null);
        }}
        name={'User Notifications'}
        menu='USER_NOTIFICATION_MODAL' />
      
      <ModalFormCreator
        open={generalNotification}
        onCreate={sentGeneralNotification}
        onCancel={() => {
          setGeneralNotification(prev => !prev)
        }}
        name={'General Notifications'}
        menu='GENERAL_NOTIFICATION_MODAL' />
    </>
  );
};

export default Users;
