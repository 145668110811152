import {
  AiOutlineUser,
} from "react-icons/ai";
import { LiaPrayingHandsSolid } from "react-icons/lia";
import { IoLanguage } from "react-icons/io5";
import { PiTag } from "react-icons/pi";
import { TbCards } from "react-icons/tb";
import { VscGraph } from "react-icons/vsc";
import { HiOutlineCpuChip } from "react-icons/hi2";
import { MdOutlineViewCarousel } from "react-icons/md";
import { Image } from "antd";
import god from '../../asset/image/god.svg'
const data = [
  {
    id: "analytics",
    icon: <VscGraph />,
    label: "Analytics",
  },

  {
    id: "darshan",
    icon: <LiaPrayingHandsSolid />,
    label: "Darashan",
  },
  {
    id: "users",
    icon: <AiOutlineUser />,
    label: "User",
  },
  {
    id: "story",
    icon: <MdOutlineViewCarousel />,
    label: "Story",
  },
  {
    id: "cards",
    icon: <TbCards />,
    label: "Cards",
  },
  {
    id: "tags",
    icon: <PiTag />,
    label: "Tags",
  },
  {
    id: "language",
    icon: <IoLanguage />,
    label: "Language",
  },
  {
    id: "god",
    icon: <div className=""><Image className="" src={god} preview={false} width={20} height={20} /></div>,
    label: "God",
  },
  {
    id: "arti",
    icon: <LiaPrayingHandsSolid />,
    label: "Arti",
  },
  {
    id: "configuration",
    icon: <HiOutlineCpuChip />,
    label: "Configuration",
  },
  //  {
  //   id: "inventory",
  //   icon: <BsBoxSeam />,
  //   label: "Inventory",
  //   subMenu: [
  //     {
  //       id: "details",
  //       icon: <BsBoxSeam />,
  //       label: "Details",
  //     },
  //     {
  //       id: "movehistory",
  //       icon: <AiOutlineHistory />,
  //       label: "Move History",
  //     },
  //     {
  //       id: "equipment",
  //       icon: <SlWrench />,
  //       label: "Equipment",
  //     },
  //     {
  //       id: "transfer",
  //       icon: <BiTransfer />,
  //       label: "Transfer",
  //     },
  //     {
  //       id: "purchase",
  //       icon: <VscRequestChanges />,
  //       label: "Purchase",
  //     },
  //   ],
  // },
  // {
  //   id: "expenses",
  //   icon: <BiWallet />,
  //   label: "Expenses",
  //   subMenu: [
  //     {
  //       id: "detail",
  //       icon: <SlWallet />,
  //       label: "Details",
  //     },
  //     {
  //       id: "Vehical",
  //       icon: <BsTruck />,
  //       label: "Vehical",
  //     },
  //   ],
  // },
  // {
  //   id: "maintenance",
  //   icon: <HiOutlineWrench />,
  //   label: "Maintenance",
  // },
  // {
  //   id: "employee",
  //   icon: <MdPeopleOutline />,
  //   label: "Employee",
  // },
  // {
  //   id: "logBook",
  //   icon: <GoBook />,
  //   label: "Log Book",
  //   subMenu: [
  //     {
  //       id: "log",
  //       icon: <RxActivityLog />,
  //       label: "Log",
  //     },
  //     {
  //       id: "ABTMeter",
  //       icon: <CgFileDocument />,
  //       label: "ABT Meter",
  //     },
  //     {
  //       id: "cleaningLog",
  //       icon: <TbFileStack />,
  //       label: "Cleaning Log",
  //     },
  //   ],
  // },
  // {
  //   id: "plantDetails",
  //   icon: <AiOutlineSetting />,
  //   label: "Plant Details",
  //   subMenu: [
  //     {
  //       id: "details",
  //       icon: <SlSettings />,
  //       label: "Details",
  //     },
  //     {
  //       id: "OMChecklist",
  //       icon: <VscChecklist />,
  //       label: "O&M Checklist",
  //     },
  //     {
  //       id: "setting",
  //       icon: <AiOutlineSetting />,
  //       label: "Setting",
  //     },
  //   ],
  // },
];
export default data;
