import { Card, Tabs } from "antd";
import React from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";

import StoryTranslation from "./tabs/Story-Translation";
import StoryEpisode from "./tabs/Story-Epidode";

const StoryDetails = () => {
  const TabsView = {
    storyTranslation: <StoryTranslation />,
    storyEpisode: <StoryEpisode />,
  };

  return (
    <>
      <Card className="mt-10">
        <Tabs
          className="p-0 m-0"
          defaultActiveKey="1"
          size="middle"
          type="card"
          items={CONSTANTS.TAB_MENU.STORY.map((ele) => {
            return {
              label: `${ele.Label}`,
              key: `${ele.id}`,
              children: TabsView[ele?.id],
            };
          })}
        />
      </Card>
    </>
  );
};

export default StoryDetails;
