import React, { useEffect } from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import moment from 'moment';
import useHttp from '../../../../hooks/use-http';
import { useNavigate } from 'react-router-dom';

const Arti = () => {
    const API = useHttp();


    const navigate = useNavigate();

    const artiUserDetail = (id) => {
        navigate(`/app/arti/arti-user/${id}`);
    };

    useEffect(() => {
        API.sendRequest(CONSTANTS.API.getTags, (res) => {
            const tags = res?.data?.rows?.map((el) => ({
                Label: el?.name,
                value: el?.id,
                id: el?.id,
            }));
            CONSTANTS.FORM_FIELD.ARTI_MODAL.find(
                (el) => el?.id === "tag"
            ).option = tags;
            CONSTANTS.FORM_FIELD.EDIT_ARTI_MODAL.find(
                (el) => el?.id === "tag"
            ).option = tags;

        });

        API.sendRequest(CONSTANTS.API.getGods, (res) => {
            const gods = res?.data?.rows?.map((el) => ({
                Label: el?.name,
                value: el?.id,
                id: el?.id,
            }));
            CONSTANTS.FORM_FIELD.ARTI_MODAL.find(
                (el) => el?.id === "god"
            ).option = gods;
            CONSTANTS.FORM_FIELD.EDIT_ARTI_MODAL.find(
                (el) => el?.id === "god"
            ).option = gods;

        });

        API.sendRequest(CONSTANTS.API.getLanguage, (res) => {
            const languages = res?.data?.rows?.map((el) => ({
                Label: el?.name,
                value: el?.id,
                id: el?.id,
            }));
            CONSTANTS.FORM_FIELD.ARTI_MODAL.find(
                (el) => el?.id === "artiLanguage"
            ).option = languages;
            CONSTANTS.FORM_FIELD.EDIT_ARTI_MODAL.find(
                (el) => el?.id === "artiLanguage"
            ).option = languages;

        });
    }, [])
    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="ARTI_LIST"
                    tableTitle="Arti List"
                    getAPI={CONSTANTS.API.getArti}
                    extraResData={"rows"}
                    getData={(res) => {
                        //   console.log(res);
                        return res?.map((data) => {
                            return {
                                ...data,
                                no: `S${data?.id.toString().padStart(7, "0")}`,
                                track: data?.previewTrack,
                                god: data?.artiGods?.map((ele) => ele?.god?.id),
                                tag: data?.artiTags?.map((ele) => ele?.tag?.id),
                                artist: data?.artist?.map(ele => ele).join(','),
                                artiLanguage: data?.artiLanguages?.map((ele) => ele?.language?.id),
                                tagD: data?.artiTags?.map((elp) => <p>{`#${elp?.tag?.name}`}</p>),
                                godD: data?.artiGods?.map((elp) => <p>{elp?.god?.name}</p>),
                                languageD: data?.artiLanguages?.map((elp) => <p>{elp?.language?.name}</p>),
                                createdAt: moment
                                    .utc(data?.createdAt)
                                    .local()
                                    .format("DD MMM, YYYY"),
                            };
                        });
                    }}
                    formData
                    modalButton="Add New Arti"
                    modalFields="ARTI_MODAL"
                    addModalTitle="Add Arti Data"
                    addAPI={CONSTANTS.API.addArti}
                    blockData
                    viewData
                    viewFunction={artiUserDetail}
                    editformData
                    editModalFields="EDIT_ARTI_MODAL"
                    editModalTitle="Edit Arti Data"
                    editAPI={CONSTANTS.API.editArti}
                    deleteData
                    deleteAPI={CONSTANTS.API.deleteArti}
                    searchfilter
                    isSearch
                    searchAPI="/admin/arti?searchQuery="
                />
            </div>
        </>
    )
}

export default Arti