import moment from 'moment';
import React, { useEffect } from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import useHttp from '../../../../hooks/use-http';

const ArtiScheduler = () => {
    const api = useHttp();


    useEffect(() => {
        api.sendRequest(CONSTANTS.API.getArti, (res) => {
            const arti = res?.data?.rows?.map((el) => ({
                Label: el?.title,
                value: el?.id,
                id: el?.id,
            }));

            CONSTANTS.FORM_FIELD.ARTI_SCHEDULER_MODAL.find(
                (el) => el?.id === "artiId"
            ).option = arti;

        });
    }, [])

    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="ARTI_SCHEDULER_LIST"
                    tableTitle="Arti Scheduler List"
                    getAPI={CONSTANTS.API.getArtiScheduler}
                    extraResData={"rows"}
                    getData={(res) => {
                        //   console.log(res);
                        return res?.map((data) => {
                            return {
                                ...data,
                                no: `S${data?.id.toString().padStart(7, "0")}`,
                                title: data?.arti?.title,
                                image: data?.arti?.image,
                                dateD: moment
                                    .utc(data?.date, "YYYY-MM-DD")
                                    .local()
                                    .format("DD MMM, YYYY"),
                                time: moment
                                    .utc(data?.time, "HH:mm:ss")
                                    .local()
                                    .format("HH:mm:ss"),
                                timeD: moment
                                    .utc(data?.time, "HH:mm:ss")
                                    .local()
                                    .format("hh:mm A"),
                            };
                        });
                    }}
                    addData
                    modalButton="Add New Schedule"
                    modalFields="ARTI_SCHEDULER_MODAL"
                    addModalTitle="Add Arti Schedule Data"
                    addAPI={CONSTANTS.API.addArtiScheduler}
                    editData
                    editModalTitle="Edit Arti Schedule Data"
                    editAPI={CONSTANTS.API.editArtiScheduler}
                    deleteData
                    deleteAPI={CONSTANTS.API.deleteArtiScheduler}
                    datefilter

                />
            </div>
        </>
    )
}

export default ArtiScheduler