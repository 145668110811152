import React from 'react'
import { useParams } from 'react-router-dom';
import PageComponent from '../../../component/common/Page-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';

const DarshanUser = () => {
    const { id } = useParams();
    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="DARSHAN_USER"
                    tableTitle="Darashan Users List"
                    getAPI={CONSTANTS.API.getUserDarshan}
                    extraParams={`&darashanId=${id}`}
                    extraResData={"rows"}
                    getData={(res) => {
                        console.log(res);
                        return res?.map((data) => {
                            return {
                                ...data,
                                no: `DU${data?.id.toString().padStart(7, "0")}`,
                                profilePic: data?.user?.profilePic,
                                name: data?.user?.name,
                                mobile: data?.user?.mobile,
                                email: data?.user?.email,
                                date: moment.utc(data?.user?.createdAt).local().format('MMM DD, YYYY'),
                                createdAt: moment.utc(data?.createdAt).local().format('MMM DD, YYYY'),



                            };
                        });
                    }}

                />
            </div>
        </>
    )
}

export default DarshanUser