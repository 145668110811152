import React, { useState } from "react";
import PageComponent from "../../../../component/common/Page-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator } from "../../../../util/functions";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";

const CardQuestion = () => {
  const [viewData, setViewData] = useState(null)
  const api = useHttp();
  const { id } = useParams();
  const showCardDetail = (dataId) => {
    // console.log(id);
    const GET_CARD_DATA_API = apiGenerator(CONSTANTS.API.getOneCardQuestionTranslations, { dataId })
    api.sendRequest(GET_CARD_DATA_API, (res) => {
      setViewData(prev => {
        return {
          ...prev,
          ...res?.data,
          options1: res?.data?.options[0],
          options2: res?.data?.options[1],
          options3: res?.data?.options[2],
          options4: res?.data?.options[3],
        }
      })
    })

  }
  // console.log(viewData);
  return (
    <>
      <PageComponent
        extraParams={`&cardId=${id}`}
        tableHeaders="CARD_QUESTION"
        tableTitle="Card Questions List"
        getAPI={CONSTANTS.API.getCardQuestionTranslations}
        extraResData="rows"
        getData={(res) => {
          // console.log(res);
          return res?.map((data) => {
            return {
              ...data,
              language: data?.language?.name,
              answers: +data?.options?.findIndex(ele => ele === data?.answer),

              no: `TQ${data?.id.toString().padStart(7, "0")}`,

              options1: data?.options[0],
              options2: data?.options[1],
              options3: data?.options[2],
              options4: data?.options[3],
            };
          });
        }}
        payloadExtra={{ cardId: id }}
        addData
        modalButton="Add New Questions"
        modalFields="QUESTION"
        addModalTitle="Add Card Question Data"
        // addAPI={null}
        addAPI={CONSTANTS.API.addCardQuestionTranslation}
        viewData
        viewFunction={showCardDetail}
        blockData
        editModalFields="QUESTION"
        editModalTitle="Edit Question "
        editData
        editAPI={CONSTANTS.API.editCardQuestionTranslation}
        deleteData
        deleteAPI={CONSTANTS.API.deleteCardQuestionTranslation}
      />
      <ModalFormCreator
        open={viewData !== null}
        onCreate={() => { }}
        onCancel={() => {
          setViewData(null);
        }}
        name=''
        menu='VIEW_QUESTION'
        formData={viewData}
        SubmitName={null}
      />
    </>
  );
};

export default CardQuestion;
