import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const Story = () => {
  const { id } = useParams();
  return (
    <>
      <div>
        <PageComponent
          tableHeaders="USER_STORY"
          tableTitle="Story Users List"
          getAPI={CONSTANTS.API.getUserStory}
          extraParams={`&userId=${id}`}
          extraResData={"rows"}
          getData={(res) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `US${data?.id.toString().padStart(7, "0")}`,
                profilePic: data?.storyEpisode?.image,
                title: data?.storyEpisode?.title,
                tag: data?.storyEpisode?.storyEpisodeTags?.map((elp) => (
                  <p>{elp?.tag?.name}</p>
                )),
                god: data?.storyEpisode?.story?.storyGods?.map((elp) => (
                  <p>{elp?.god?.name}</p>
                )),
                date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY')
                
              };
            });
          }}

        />
      </div>

    </>
  )
}

export default Story